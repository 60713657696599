import * as React from "react"
import { graphql } from "gatsby"

import Layout from "./layout2022"
import Seo from "../../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const JournalIndex = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Seo title="220712" />
      <div className="text-center">
        <StaticImage
          formats={["auto", "webp", "avif"]}
          src="../../../images/journal/2022/sketch_220712_1.jpg"
          width={500}
          quality={95}
          />
      </div>
      <div className="pt-4">
        <p>I've got into some korean drama lately, and that brought me to start learning korean. Before getting out of my bed every morning, taking one lesson on study app has been my daily habit these days.</p>
        <p>Today was the first day to start learning Hangeul, and studied vowels this morning. I hope I can read Hangeul soon because it makes me understand easier when look up words and those of pronunciations in a dictionary.</p>
        <p>These days I find it difficult to meet some interesting animes, so thinking to watch korean dramas instead. I haven't watched korean dramas at all so far, so now I have many choices!</p>
      </div>
      <div className="row mb-5">
        <div className="col-6">
          <StaticImage
            formats={["auto", "webp", "avif"]}
            src="../../../images/journal/2022/sketch_220712_2.jpg"
            width={500}
            quality={95}
            />
        </div>
        <div className="col-6">
          <StaticImage
            formats={["auto", "webp", "avif"]}
            src="../../../images/journal/2022/sketch_220712_3.jpg"
            width={500}
            quality={95}
            />
        </div>
      </div>
      <p>(I've got a new muji's notebook for watercolor drawing today!)</p>
    </Layout>
  )
}

export default JournalIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
